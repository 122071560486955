var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "b-card",
                { attrs: { header: "true", "header-tag": "header" } },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between align-items-center",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c(
                        "p",
                        { staticClass: "mb-0 d-inline-block align-middle" },
                        [_c("b", [_vm._v("Dettaglio Log")])]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("b-row", [
                    _c("div", { staticClass: "col-md-4 border-bottom" }, [
                      _vm._v("Testo"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-md-8 border-bottom" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.data.data.content.resolvedContent.text_content
                          ) +
                          "\n        "
                      ),
                    ]),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }